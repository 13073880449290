import React, { useLayoutEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ColorConstants } from 'app/Constants';
import { NotFound } from 'components/NotFound/Container/NotFound';
import KfcActivityIndicator from './KfcActivityIndicator';
import LoginAnalyticsHandler from 'app/LoginAnalyticsHandler';
import PrivateRoute from 'app/PrivateRoutes';
import OutOfStockCartError from 'app/OutOfStockCartError';
import HeaderFooterSwitch from 'app/HeaderFooterSwitch';
import KFCRouter from 'app/KFCRouter';
import BrandPageRoute from 'components/BrandPageRoute';
import { contentfulActions, selectContentfulResponse } from '@kfc-global/react-shared/redux/Slices/ContentfulSlice';
import { useAppDispatch, useAppSelector } from '@kfc-global/react-shared/redux/Store/hooks';
import { getCmsClient } from '../common/utilities/cmsClient';
import isEmpty from 'lodash/isEmpty';
import { ToastContainer } from 'react-toastify';

const ROUTES_IMPORT = {
  HomePageContainer: import('components/homePageContainer'),
  SitemapContainer: import('components/SeoPage'),
  // fixme: Saiful - this is a temporary container. Once release-in is merged into sopac, delete it
  SitemapContainerIn: import('components/SeoPage/Container/SitemapContainerIn'),
  ProductLandingPage: import('components/productLandingPage'),
  DealsPageContainer: import('components/dealsPageContainer'),
  CartPageContainer: import('components/cartPage'),
  Checkout: import('components/checkout'),
  PrivacyPolicyComponent: import('components/PrivacyPolicyPage'),
  OurAdsComponent: import('components/ourAdsPage/Container/OurAdsComponent'),
  PaymentProcess: import('components/paymentProcessPage'),
  PaymentStatusCheck: import('components/paymentStatusCheck'),
  PaymentFailed: import('components/paymentFailed'),
  AppUpdateContainer: import('components/AppUpdate'),
  FooterFaq: import('components/FooterFaq'),
  FooterGiftcard: import('components/FooterGiftcard/GiftcardComponent'),
  ContactUs: import('components/contactUsPage/Container/ContactUsContainer'),
  TermsOfUseComponent: import('components/TermsOfUsePage'),
  GetHelpContainer: import('components/getHelp'),
  SignOutPage: import('components/SignOutPage'),
  AccountContainer: import('components/accountPage'),
  LoginAnalyticsHandler: import('app/LoginAnalyticsHandler'),
  OrderReceiptDataModal: import('components/OrderDetails/OrderReceiptDataModal'),
  ProductDescriptionPage: import('components/productDescriptionPage'),
  TrackOrder: import('components/trackOrder'),
  ActiveOrders: import('components/activeOrders'),
  PromosRewardsContainer: import('organisms/PromosRewards/Container/PromosRewardsLandingPage'),
  OrderProcessing: import('components/orderProcessing'),
  OrderSummary: import('components/orderSummary'),
  RestaurantDetailPage: import('components/restaurantDetailPage'),
  ProtectedDisclosureComponent: import('components/ProtectedDisclosurePage/Container/AU/ProtectedDisclosure'),
  ContactKFC: import('components/contactUsPage'),
  ThankYouPage: import('components/ThankYouPage'),
  SignupComponent: import('components/SignupComponent/Container/SignupComponent'),
  NutritionAllergenLandingPage: import('components/NutritionAllergenLandingPage'),
  FindAKFC: import('components/FindAKFC'),
  AddHopeThankYou: import('components/AddHope/ThankYou'),
  AddHopeDonate: import('components/AddHope/Donate'),
};

export const RouteComponent = ({ name, ...rest }) => {
  const Component = React.useMemo(() => {
    const dynamicRouteElement = ROUTES_IMPORT[name];
    return dynamicRouteElement && React.lazy(() => dynamicRouteElement);
  }, [name]);

  return name && Component ? (
    <React.Suspense fallback={<div />}>
      <Component {...rest} />
    </React.Suspense>
  ) : null;
};

const RouterInfo = () => {
  const contentfulResponse = useAppSelector(selectContentfulResponse) ?? {};
  const { items: [{ fields: { routes: routesJson = [] } = {} }] = [{}] } = contentfulResponse;

  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    dispatch(
      contentfulActions.fetch({
        contentful: getCmsClient(),
        query: {
          content_type: 'routeConfig',
          include: '5',
          select: 'sys.id,fields.routes',
        },
      }),
    );
  }, [dispatch]);

  return (
    <>
      {!isEmpty(routesJson) && (
        <KFCRouter>
          <LoginAnalyticsHandler>
            <div id='app-container' style={{ backgroundColor: ColorConstants.WHITE }}>
              <HeaderFooterSwitch>
                
                <Switch>
                  {routesJson.map(({ path, name, isPrivate = false, exact = false, props: additionalProps }, idx) => {
                    if (isPrivate) {
                      return (
                        <PrivateRoute
                          key={`private-route-${idx}`}
                          exact={exact}
                          path={path}
                          name={name}
                          additionalProps={additionalProps}
                        />
                      );
                    }
                    return (
                      <Route
                        key={`route-${idx}`}
                        exact={exact}
                        path={path}
                        render={props => (
                          <div className='wrapper-base-component'>
                          <RouteComponent
                            data-testid={`route-testid-${idx}`}
                            name={name}
                            {...{ ...props, ...additionalProps }}
                          />
                          </div>
                        )}
                      />
                    );
                  })}
                  <BrandPageRoute />
                  <Route component={NotFound} />
                </Switch>
              </HeaderFooterSwitch>
            </div>
          </LoginAnalyticsHandler>
          <KfcActivityIndicator />
          <OutOfStockCartError />
          <ToastContainer limit={1} />
          {/*RENDEV-15218 Moving ToastContainer inside router to get routing objects like useHistory, Link etc.*/}
        </KFCRouter>
      )}
    </>
  );
};

export default RouterInfo;
